import React from "react";
import { Helmet } from "react-helmet";
import Header from "../common/Header";
import Footer from "../common/Footer";

function HairTexturing() {
  let Background = "/images/hair-texturing.jpg";
  return (
    <div className="hairtexturingContainer">
      <Helmet>
        <title>Hair Texturing Services - Era Salon</title>
        <meta
          name="description"
          content="Transform your hair with our texturing services at Era Salon. We offer a variety of texturing techniques to suit your style."
        />
        <meta
          name="keywords"
          content="Hair Texturing, Perms, Relaxers, Salon, Era Salon, Citrus Heights"
        />
      </Helmet>
      <div className="fluid-container">
        <Header />
        <div
          className="jumbotron jumbotron-fluid"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            height: "400px",
          }}
        >
          <div className="container">
            <h1 className="display-4 text-center">Hair Texturing</h1>
          </div>
        </div>
        <div className="container">
          <div className="hair">
            <h2>Hair Texturing</h2>
            <p>
              <strong>
                Have you ever wished that your hair was more manageable? Softer?
                Smoother? Had more shine?
              </strong>
            </p>
            <p>
              Hair texture and curls are better than ever. Perms are gentle and
              kinder to the hair. They are perfect for lift, support, and adding
              bounce to your style.
            </p>
            <p>
              Come to our <strong>"Hair Salon"</strong> to get your curls
              looking right, with that bounce that will have all of the heads
              turning. We offer quality hair texturing services and products for
              beautiful hair.
            </p>
            <p>
              When the texturizing technique is performed correctly by our hair
              stylists, it appears to give the hair a more brilliant looking
              texture whether they’ve chosen to have their hair straightened,
              relaxed, or permed.
            </p>
            <p>
              <strong>
                Our hair salon offers all of the following hair texture
                services. Prices for each service are based on how long your
                hair is.
              </strong>
            </p>
            <ul>
              <li>Perm</li>
              <li>Relaxer</li>
              <li>Japanese Relaxer</li>
              <li>Keratin Complex</li>
              <li>Keratin Express Blowout</li>
              <li>Hair Extensions</li>
            </ul>
            <p>
              <strong>
                We offer a wide variety of top quality services to fit your
                needs. Our experienced staff will help you determine the best
                services for your needs and deliver the kind of quality and
                value you expect from a professional hair salon. Our salon is
                designed to make your experience enjoyable and leave you feeling
                inspired.
              </strong>
            </p>
            <p>
              <strong>Benefits of texturizing by "Hair Salon" are:</strong>
            </p>
            <ul>
              <li>Permanent re-texturizing results</li>
              <li>Natural bounce and volume</li>
              <li>Healthy shine</li>
              <li>Retouch needed only every 6-8 months</li>
              <li>Versatile finishes from silky straight to smooth curls</li>
              <li>Always Formaldehyde-free</li>
            </ul>
            <p>
              We use Keratin Complex Smoothing Therapy. This revolutionary
              smoothing system infuses Keratin deep into the hair cuticle,
              eliminating up to ninety-five percent of frizz and curl and
              leaving the hair smooth, shiny and luxurious. Results typically
              last 3 to 6 months depending on hair type.
            </p>
            <p>
              It rejuvenates and revitalizes the hair, giving it a softer,
              silkier feel that everyone raves about. Unlike other salon
              services, the more you treat your hair, the healthier it becomes.
              With each treatment, the build-up of Keratin makes the hair:
            </p>
            <ul>
              <li>Promotes Healing</li>
              <li>Blocks the effects of humidity</li>
              <li>Prevents environmental toxins from entering the hair.</li>
            </ul>
            <p>
              <strong>
                Keratin Complex Smoothing Therapy is gentle enough to use on all
                hair types: color treated, chemically processed, bleached or
                highlighted. This service is available in our salons and is
                administered by specially trained stylists in the Keratin
                Complex Smoothing Therapy methods.
              </strong>
            </p>
            <p>
              We remain committed to helping hairdressers create beauty around
              the world. Contact us for more information at{" "}
              <a href="tel:5611231234">(561) 123-1234</a>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default HairTexturing;
