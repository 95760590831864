import React from "react";

import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export default function Header() {
  return (
    <header>
      <div className="fluid-container">
        <Navbar expand="lg">
          <div className="container">
            <Navbar.Brand>
              <LinkContainer to="/">
                <Nav.Link>ERA SALON</Nav.Link>
              </LinkContainer>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                {/* <Nav.Link href='#link'>Link</Nav.Link> */}
                <NavDropdown title="Hair Care Services" id="basic-nav-dropdown">
                  <NavDropdown.Item>
                    <LinkContainer to="/hair-styling">
                      <Nav.Link>Hair Styling</Nav.Link>
                    </LinkContainer>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <LinkContainer to="/hair-coloring">
                      <Nav.Link>Hair Coloring</Nav.Link>
                    </LinkContainer>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <LinkContainer to="/hair-texturing">
                      <Nav.Link>Hair Texturing</Nav.Link>
                    </LinkContainer>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <LinkContainer to="/waxing">
                      <Nav.Link>Waxing</Nav.Link>
                    </LinkContainer>
                  </NavDropdown.Item>
                </NavDropdown>

                <LinkContainer to="/facial-care">
                  <Nav.Link>Facial Care</Nav.Link>
                </LinkContainer>

                <NavDropdown title="Nail Care Services" id="basic-nav-dropdown">
                  <NavDropdown.Item>
                    <LinkContainer to="/nail-care">
                      <Nav.Link>Nail Care</Nav.Link>
                    </LinkContainer>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <LinkContainer to="/other-care">
                      <Nav.Link>Pedicure and Foot Care</Nav.Link>
                    </LinkContainer>
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="About Us" id="basic-nav-dropdown">
                  <NavDropdown.Item>
                    <LinkContainer to="/about">
                      <Nav.Link>About Us</Nav.Link>
                    </LinkContainer>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <LinkContainer to="/team">
                      <Nav.Link>Our Team</Nav.Link>
                    </LinkContainer>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <LinkContainer to="/gallery">
                      <Nav.Link>Gallery</Nav.Link>
                    </LinkContainer>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <LinkContainer to="/contact">
                      <Nav.Link>Contact Us</Nav.Link>
                    </LinkContainer>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav className="mr-auto">
                <div className="headerSocial">
                  <a href="tel:9167221888">
                    {" "}
                    <i className="fas fa-phone"></i>{" "}
                    <span className="p-1">(916) 722-1888</span>
                  </a>
                  <a
                    href="https://www.facebook.com/Era-salon-305929286474054"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook p-1"></i>
                  </a>
                  <a
                    href="https://www.google.com/search?sxsrf=ALeKk00mS7SVFP8jWvuxNcrEYyMjfa7DmQ%3A1597278395648&ei=u4g0X9KFJ4H49AP4jongCA&q=era+salon+sac&oq=era+salon+sac&gs_lcp=CgZwc3ktYWIQAzIICAAQsAMQzQIyCAgAELADEM0CMggIABCwAxDNAlAAWABg9MwBaABwAHgAgAFniAFnkgEDMC4xmAEAqgEHZ3dzLXdpesABAQ&sclient=psy-ab&ved=0ahUKEwiSz4Tb9ZbrAhUBPH0KHXhHAowQ4dUDCAw&uact=5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-google p-1"></i>
                  </a>
                  <a
                    href="https://www.yelp.com/biz/era-salon-citrus-heights-4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-yelp p-1"></i>
                  </a>
                </div>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    </header>
  );
}
