import React from "react";
import { Helmet } from "react-helmet";
import Header from "../common/Header";
import Footer from "../common/Footer";

function OtherCare() {
  let Background = "/images/other-care.jpg";
  return (
    <div className="othercareContainer">
      <Helmet>
        <title>Other Care Services - Era Salon</title>
        <meta
          name="description"
          content="Explore our range of other care services at Era Salon. From massages to body treatments, we have a variety of services to meet your needs."
        />
        <meta
          name="keywords"
          content="Other Care, Massages, Body Treatments, Salon, Era Salon, Citrus Heights"
        />
      </Helmet>
      <div className="fluid-container">
        <Header />
        <div
          className="jumbotron jumbotron-fluid"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            height: "400px",
          }}
        >
          <div className="container">
            <h1 className="display-4 text-center">Pedicure and Manicure</h1>
          </div>
        </div>
        <div className="container">
          <div className="hair">
            <h2>Pedicure and Manicure</h2>
            <p>
              Pedicure &amp; Manicure Ready to impress your friends with the
              latest in nail styles for Spring and Summer?
            </p>
            <p>
              At <strong>"ERA SALON SAC"</strong> you will be treated to the
              latest and the finest in style and quality.
            </p>
            <p>
              Come and experience our manicure and pedicure services.
              Incorporate some indulgent add-ons like a paraffin soak and hot
              stone massage to rise to another level of relaxation, comfort and
              style.
            </p>
            <p>
              Our hands and feet receive the greatest amount of “punishment” in
              our daily lives. They deserve some extra gentle care to keep them
              soft and supple. The manicures and pedicures we provide are
              specially designed to assist you in keeping them healthy and
              limber for dealing with all that occurs in your lives.
            </p>
            <p>
              Soft, supple, young looking hands are smooth and hydrated. We
              exfoliate your hands to make them soft.
            </p>
            <p>
              Let us pamper you with a manicure. After shaping, cuticle care,
              buffing and exfoliation, you will be treated to a moisturizing
              masque to help re-hydrate your skin and a massage to improve
              circulation. Once all of this is complete, the process is finished
              off with your choice of polish. You will be so delighted with the
              end results.
            </p>
            <p>
              <strong>
                Following are the main benefits of manicure / pedicure:
              </strong>
            </p>
            <ul>
              <li>
                Manicure / pedicure are wonderful ways to pamper you as it is a
                deeply relaxing beauty treatment
              </li>
              <li>It helps reduce aging of skin on hand / feet</li>
              <li>It helps reduce wrinkling of skin on hand / feet</li>
              <li>
                It helps stop many nail disorders like splits, scratches,
                fragile tips etc.
              </li>
              <li>It helps give you naturally strong nails for hand hands</li>
              <li>
                So combined effect is your hands and feet feel and looks
                beautiful
              </li>
            </ul>
            <p>
              You take your feet with you, everywhere you go. Isn’t it time you
              gave them some TLC? Come and relax in comfort at "Nail Salon" and
              partake one of our luxury pedicures. Botanicals, scrubs, callus
              removal and massages to invigorate your feet are all part of the
              process. Sit back, unwind and indulge your hands and feet in a
              calming treatment that softens the skin, and restores and
              strengthens your nails. “Nail Salon“ even offers specialized
              treatments for men and young girls. Because we know that everyone
              could use some rejuvenating.
            </p>
            <p>
              In order for you to receive the best possible service, we invite
              you to get to know us – to learn about us and our services and
              products.
            </p>
            <p>
              Our services are as unique and individual as you are. It’s
              important that you know that you are special and your needs are of
              utmost importance to us. Put yourself in the hands of our expert
              hair stylists, cosmetologists, nail techs, skincare specialists
              and make-up artists. They will transform you.
            </p>
            <p>
              The refresher service offers a soak, shaping of the nails and
              choice of color. Contact us at{" "}
              <a href="tel:9167221888">(916) 722-1888</a> for more information.
            </p>
            <p>
              Come and experience a luxurious custom-blend manicure ritual that
              is designed to promote healthy nails with the use of products that
              make your nails strong, flexible and resilient – just as nature
              intended.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default OtherCare;
