import React from "react";
import { Helmet } from "react-helmet";
import Header from "../common/Header";
import Footer from "../common/Footer";

function HairColoring() {
  let Background = "/images/hair-coloring.jpg";
  return (
    <div className="haircoloringContainer">
      <Helmet>
        <title>Hair Coloring Services - Era Salon</title>
        <meta
          name="description"
          content="Explore our professional hair coloring services at Era Salon. Whether you're looking for a new color or highlights, we've got you covered."
        />
        <meta
          name="keywords"
          content="Hair Coloring, Highlights, Salon, Era Salon, Citrus Heights"
        />
      </Helmet>
      <div className="fluid-container">
        <Header />
        <div
          className="jumbotron jumbotron-fluid"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            height: "400px",
          }}
        >
          <div className="container">
            <h1 className="display-4 text-center">Hair Coloring</h1>
          </div>
        </div>
        <div className="container">
          <div className="hair">
            <h2>Hair Coloring</h2>
            <p>
              <strong>
                Considering a completely new style is one thing but how else can
                you add zing to your locks?
              </strong>
            </p>
            <p>
              When you’re ready for a makeover or to add a little excitement, a
              change of hair color is a great choice. A change in your hair
              color may be a semi-permanent hair color to subtle highlights. At{" "}
              <strong>"ERA SALON SAC"</strong> Your team of creative designers
              will assist you in deciding on the most desirable results for your
              hair, from color choice to ensuring that you end up with the look
              you want to achieve.
            </p>
            <p>
              We will also make sure that you’re in the right pair of
              professional hands.
            </p>
            <p>
              <strong>
                Our Hair Coloring Services are some of our most popular services
                in our salons and giving our guests a top-notch professional
                experience is something we pride ourselves on.
              </strong>
            </p>
            <p>
              To meet your hair color needs, a free hair color consultation from
              our professional stylists is essential and will come prior to us
              working our magic on that day. This offer is completely free and
              our stylist will work with you one-on-one to work out exactly what
              you want.
            </p>
            <p>
              You thought that you’d save some time and money by doing a quick
              color treatment at home. Disaster. You have nothing to worry
              about. An appointment with one of our talented color artists will
              have you looking and feeling like a million dollars in no time.
            </p>
            <p>
              We make certain that the hair that is already stressed is treated
              with the delicate care it requires.
            </p>
            <p>
              You will always know the level of skill of each of the designers
              that are available for you. You may select the perfect stylist who
              has the level of experience you require in the budget range you
              can afford.
            </p>
            <p>
              Each stylist undergoes an extensive level of testing and training
              to ensure that you always receive the best possible care. Many of
              our employees started with our company as salon assistants.
              Promising employees have received additional education from our
              Master and Director level specialists.
            </p>
            <p>
              <strong>
                Our Artistic Directors train each designer in our salon to
                ensure we always give the best possible service to all of our
                clients. How to add highlights that look natural. Creating a
                delightful sparkle and shine. How mask or blend grey hair.
              </strong>
            </p>
            <p>
              Ever had a "color disaster"? Liked the color on the box and it
              looked hideous on you? We have experts who know precisely the
              correct color to use for you. Colors that highlight your features
              and your nature. When you trust us with your color needs, your
              treatment includes shampoo and conditioner. Our stylists will be
              happy to do a blow dry styling with your highlights appointment.
            </p>
            <p>
              We’d and will want to come again. You are most welcome to come in
              for a visit. Come in and spend a relaxing afternoon, while we
              spoil and indulge you, in our comfortable salon. Our services are
              budget friendly, too.
            </p>
            <p>
              <strong>
                We use Ammonia free hair colors as well as natural source of
                color.
              </strong>
            </p>
            <p>
              We do <strong>Organic</strong> and <strong>Natural Hair</strong>{" "}
              color your hair in a safe and natural way. This is often a great
              option for those who are pregnant, nursing, allergic to
              traditional chemical color products, or have health problems and
              are concerned about chemical exposure.
            </p>
            <p>
              <strong>
                To make your next color treatment or color removal appointment,
                call us at: <a href="tel:9167221888">(916) 722-1888</a>
              </strong>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default HairColoring;
