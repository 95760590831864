import React from "react";
import { Helmet } from "react-helmet";
import Header from "../common/Header";
import Footer from "../common/Footer";

import { Alert } from "react-bootstrap";

function Waxing() {
  let Background = "/images/waxing.jpg";
  return (
    <div className="waxingContainer">
      <Helmet>
        <title>Waxing Services - Era Salon</title>
        <meta
          name="description"
          content="Enjoy smooth and hair-free skin with our professional waxing services at Era Salon. We offer a variety of waxing options to suit your needs."
        />
        <meta
          name="keywords"
          content="Waxing, Hair Removal, Salon, Era Salon, Citrus Heights"
        />
      </Helmet>
      <div className="fluid-container">
        <Header />
        <div
          className="jumbotron jumbotron-fluid"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            height: "400px",
          }}
        >
          <div className="container">
            <h1 className="display-4 text-center">Waxing</h1>
          </div>
        </div>
        <div className="container">
          <div className="hair">
            <Alert variant="info">
              <Alert.Heading>
                <i class="fas fa-info-circle fa-2x"></i>
              </Alert.Heading>
              <p>
                We currently only specialize in offering our brazilian waxing
                services to{" "}
                <strong style={{ textDecoration: "underline" }}>
                  females only
                </strong>{" "}
                and{" "}
                <strong style={{ textDecoration: "underline" }}>DO NOT</strong>{" "}
                offer any Waxing services to males. We apologize for this
                inconvenience.
              </p>
            </Alert>
            <h2>Waxing</h2>
            <p>
              Waxing Services. We understand that waxing is personal, so we're
              committed to making you comfortable, both through friendly,
              straightforward service and the very best waxing products
              available.
            </p>
            <p>
              "Beauty Salon" is dedicated to the art of waxing and you'll enjoy
              the best waxing services you've ever experienced.
            </p>
            <p>
              <strong>We offer:</strong>
            </p>
            <ul>
              <li>
                Facial waxing for lip, brow and chin * Body waxing for bikini,
                underarm, arms, legs, chest and back
              </li>
              <li>
                Waxing used to elicit winces of pain. Our wax treatment
                specialists take great care to ensure the health of your skin.
                Warm wax is applied to the area and removed as gently as
                possible – leaving clean, smooth skin. We are dedicated to your
                comfort.
              </li>
            </ul>
            <p>
              Waxing used to elicit winces of pain. Our wax treatment
              specialists take great care to ensure the health of your skin.
              Warm wax is applied to the area and removed as gently as possible
              – leaving clean, smooth skin. We are dedicated to your comfort.
            </p>
            <p>
              If you have sensitive skin, waxing may be the preferred treatment
              for you.
            </p>
            <p>
              Our wax is ideal for sensitive skin because it is made from all
              natural ingredients like pine resin and bees wax and is applied at
              body temperature. When waxing is done on a regular basis, it
              actually saves time.
            </p>
            <p>
              Following wax hair removal, the results may last between four and
              eight weeks. Consider the amount of time you will be saving from
              your morning routine.
            </p>
            <p>
              We have handpicked the highest quality products for our waxing
              services and make special use of hard wax for those super
              sensitive parts. We also carry a great line of retail products,
              including our Skin Perfecting Scrub, at the Wax Bar to help with
              all your before and after wax care.
            </p>
            <p>
              Allow us to use our extensive experience to gently and effectively
              remove any unsightly hair. We do our best to remove the hair as
              gently as possible and to help you in looking and feeling your
              very best.
            </p>
            <p>
              Our waxing services will leave your skin hair free and fabulously
              smooth. Contact us for more information at{" "}
              <a href="tel:9167221888">(916) 722-1888</a>.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Waxing;
