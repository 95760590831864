import React from "react";
import { Helmet } from "react-helmet";
import Header from "../common/Header";
import Footer from "../common/Footer";

function About() {
  let Background = "/images/about.jpg";
  return (
    <div className="aboutContainer">
      <Helmet>
        <title>About Us - Era Salon</title>
        <meta
          name="description"
          content="Learn more about Era Salon, our mission, and our team of expert stylists and beauticians dedicated to providing the best services in Citrus Heights."
        />
        <meta
          name="keywords"
          content="About Era Salon, Salon, Beauty Services, Citrus Heights"
        />
      </Helmet>
      <div className="fluid-container">
        <Header />
        <div
          className="jumbotron jumbotron-fluid"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            height: "400px",
          }}
        >
          <div className="containerac">
            <h1 className="display-4 text-center">About Us</h1>
          </div>
        </div>
        <div className="container">
          <div className="aboutContainer p-3">
            <p>
              "ERA SALON SAC" is the premier hair salon. We offer a variety of
              hair and makeup services and are conveniently located for you.
              Although our prices vary to accommodate a variety of guests, each
              of our stylists has completed an extensive training program.
              Regardless of the level of stylist you choose, you can be
              confident that you will receive incredibly high quality services.
            </p>
            <h2>OUR PHILOSOPHY</h2>
            <p>
              Whether you're visiting us for a quick haircut or prefer to spend
              the afternoon being pampered at our full service salon, our
              philosophy remains the same. Our focus is you, our client. We are
              committed to providing a quality experience. We look forward to
              seeing you soon.
            </p>

            <h2>OUR MISSION</h2>
            <p>
              We work together as a team to better serve our clients. Our
              service is infused with the diversity of our staff. Development of
              each staff member’s education is vital to the success of our
              business. Your satisfaction is our number one priority. We work
              together in teams to ensure that we deliver on our promise. Our
              goals are:
            </p>
            <ul>
              <li> Do a great job on your hair.</li>

              <li> Provide the best possible customer service.</li>

              <li> Continue our education.</li>

              <li>
                {" "}
                Always learn new things, learn from each other and improve,
              </li>

              <li>
                {" "}
                Create an uplifting, positive environment for clients and staff,
              </li>

              <li>
                {" "}
                Make the most environmentally sound choices in our products and
                in our actions,
              </li>

              <li>
                {" "}
                Provide healthier alternatives to products, including colors and
                hair care,
              </li>

              <li>
                {" "}
                Strive to reflect the highest ethical standards in everything we
                do
              </li>
            </ul>
            <p>
              We believe that you deserve excellent service in a fantastic salon
              environment and that you should be treated as an individual and
              not just a number.
            </p>
            <p>
              Contact us for more information at{" "}
              <a href="tel:9167221888">(916) 722-1888</a>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default About;
