import React from "react";
import { Helmet } from "react-helmet";
import Header from "../common/Header";
import Footer from "../common/Footer";

function Team() {
  let Background = "/images/team.jpg";
  return (
    <div className="teamContainer">
      <Helmet>
        <title>Meet Our Team - Era Salon</title>
        <meta
          name="description"
          content="Get to know the talented team of stylists and beauticians at Era Salon. Our experts are here to provide you with exceptional beauty services."
        />
        <meta
          name="keywords"
          content="Our Team, Stylists, Beauticians, Era Salon, Salon, Beauty Services"
        />
      </Helmet>
      <Header />
      <div className="fluid-container">
        <div
          className="jumbotron jumbotron-fluid"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            height: "400px",
          }}
        >
          <div className="container">
            <h1 className="display-4 text-center">Our Team</h1>
          </div>
        </div>
        <div className="container">
          <div className="team">
            <div className="row">
              <div className="col-lg-3">
                <div className="teamInfo text-center">
                  <img
                    src="/images/team-jenny.jpg"
                    className="img-thumbnail rounded"
                    alt="Jenny"
                  />
                  <p className="pt-3">
                    <strong>Jenny Heng - Manager</strong>
                  </p>
                  <p>Hair Cut &amp; Color, Waxing</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="teamInfo text-center">
                  <img
                    src="/images/team-myle.jpg"
                    className="img-thumbnail rounded"
                    alt="Myle"
                  />
                  <p className="pt-3">
                    <strong>MYLE - Hair Dresser</strong>
                  </p>
                  <p>Hair Cut - Color</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="teamInfo text-center">
                  <img
                    src="/images/team-ann.jpg"
                    className="img-thumbnail rounded"
                    alt="Ann"
                  />
                  <p className="pt-3">
                    <strong>Ann Troung - Team Member</strong>
                  </p>
                  <p>Nails Care &amp; Skin Care</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="teamInfo text-center">
                  <img
                    src="/images/tony.jpg"
                    className="img-thumbnail rounded"
                    alt="Tony"
                  />
                  <p className="pt-3">
                    <strong>Tony - Team Member</strong>
                  </p>
                  <p>Nails &amp; Pedicutres</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Team;
