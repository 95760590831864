import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Team from "./components/Team";
import HairStyling from "./components/HairStyling";
import HairColoring from "./components/HairColoring";
import HairTexturing from "./components/HairTexturing";
import Waxing from "./components/Waxing";
import NailCare from "./components/NailCare";
import OtherCare from "./components/OtherCare";
import Gallery from "./components/Gallery";
import Contact from "./components/Contact";
import FacialCare from "./components/FacialCare";
import NoMatch from "./components/NoMatch";

export default (
  <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={About} />
      <Route exact path="/team" component={Team} />
      <Route exact path="/hair-styling" component={HairStyling} />
      <Route exact path="/hair-coloring" component={HairColoring} />
      <Route exact path="/hair-texturing" component={HairTexturing} />
      <Route exact path="/facial-care" component={FacialCare} />
      <Route exact path="/waxing" component={Waxing} />
      <Route exact path="/nail-care" component={NailCare} />
      <Route exact path="/other-care" component={OtherCare} />
      <Route exact path="/gallery" component={Gallery} />
      <Route exact path="/contact" component={Contact} />
      <Route component={NoMatch} />
    </Switch>
  </Router>
);
