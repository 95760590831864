import React from "react";
import { Helmet } from "react-helmet";
import Header from "../common/Header";
import Footer from "../common/Footer";

function NailCare() {
  let Background = "/images/nail-care.jpg";
  return (
    <div className="nailcareContainer">
      <Helmet>
        <title>Nail Care Services - Era Salon</title>
        <meta
          name="description"
          content="Pamper yourself with our nail care services at Era Salon. From manicures to pedicures, we have everything to keep your nails looking fabulous."
        />
        <meta
          name="keywords"
          content="Nail Care, Manicure, Pedicure, Salon, Era Salon, Citrus Heights"
        />
      </Helmet>
      <div className="fluid-container">
        <Header />
        <div
          className="jumbotron jumbotron-fluid"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            height: "400px",
          }}
        >
          <div className="container">
            <h1 className="display-4 text-center">Nail Care</h1>
          </div>
        </div>
        <div className="container">
          <div className="hair">
            <h2>Nail Care</h2>
            <p>
              We are famous for our nail services, all delivered in a peaceful
              and relaxed atmosphere.
            </p>
            <p>
              At <strong>ERA Salon</strong>, you are assured of a comfortable
              and spotless environment where you may relax and melt away the
              stresses of your day
            </p>
            <p>
              Choose from our vast array of services and colors, all available
              at the most reasonable rates.
            </p>
            <p>
              <strong>The services that we offer:</strong>
            </p>
            <ul>
              <li>Pedicure</li>
              <li>Nails Enhancement</li>
              <li>Natural Nail Care</li>
            </ul>
            <p>
              We aim to deliver premier service in an intimate, relaxed setting.
              Our friendly staff are here to assist you in any way you need. We
              also invite you to enjoy complimentary beverages. Our loyalty
              program also saves money for you.
            </p>
            <p>
              We employ only the most artistic and professional technicians. Our
              nail care technology is top of the line and our nail care products
              are only the finest.
            </p>
            <p>
              Our professional nail artists will always bring you the latest in
              designs. Our manicurists only use the best nail care products to
              achieve the best results in nail care treatment.
            </p>
            <p>
              You may rest assured that you will be properly cared for in our
              licensed and insured salon. We are set to help you with every
              facet of your nail care requirements
            </p>
            <p>
              Relax and let us pamper you from head to toe. Choose from the
              ultimate pedicure, a hot oil manicure, acrylic nails, waxing
              services.
            </p>
            <p>
              Sanitation is of the utmost concern us. Hospital grade
              disinfectant is used to ensure against the transmission of nail
              fungi and other bacteria, thus assuring our clients of having a
              safe salon experience. We’ve built our reputation and image on
              meeting our client’s needs first.
            </p>
            <p>
              We are staffed by licensed professionals; including Nail
              Technicians providing manicures and pedicures. Our goal is to
              ensure that your visit is the most relaxing and enjoyable
              experience. We guarantee you’ll receive our fullest attention,
              best treatment and excellent customer service.
            </p>
            <p>
              To enhance your experience and cater to all of your needs, we use
              only the highest quality professional products available. Contact
              us for more information at{" "}
              <a href="tel:9167221888">(916) 722-1888</a>.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default NailCare;
