import React from "react";
import { Helmet } from "react-helmet";
import Header from "../common/Header";
import Footer from "../common/Footer";

const FacialCare = () => {
  let Background = "/images/facial-care.jpg";
  return (
    <div className="facial-care-container">
      <Helmet>
        <title>Facial Care Services - Era Salon</title>
        <meta
          name="description"
          content="Indulge in our facial care services at Era Salon. Our treatments are designed to rejuvenate and refresh your skin for a glowing complexion."
        />
        <meta
          name="keywords"
          content="Facial Care, Skincare, Facials, Salon, Era Salon, Citrus Heights"
        />
      </Helmet>
      <div className="fluid-container">
        <Header />
        <div
          className="jumbotron jumbotron-fluid"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            height: "400px",
          }}
        >
          <div className="container">
            <h1 className="display-4 text-center">Facial Services</h1>
          </div>
        </div>
        <div className="container">
          <div className="hair">
            <h2>Eyelash Extensions</h2>
            <p>
              Our professionals specialize in offering eyelash extensions to
              make sure you look your best when you enter our building and look
              beautiful when you leave!
            </p>
            <h2>Permanate Makeup</h2>
            <p>
              We have a wide array of options available to you. If you would
              like a certain kit feel free to ask us and we can accommodate you!
            </p>
            <h2>Ear piercing</h2>
            <p>
              We can piece one or both ears and can offer special designs based
              on your requirements to make sure you get the best service
              possible!
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default FacialCare;
