import React from "react";
import { Helmet } from "react-helmet";
import Header from "../common/Header";
import Footer from "../common/Footer";

function Contact() {
  let Background = "/images/contactimage.png";
  return (
    <div className="contactContainer">
      <Helmet>
        <title>Contact Us - Era Salon</title>
        <meta
          name="description"
          content="Get in touch with Era Salon for inquiries, appointments, or any questions you may have. We're here to help!"
        />
        <meta
          name="keywords"
          content="Contact, Inquiries, Appointments, Salon, Era Salon, Citrus Heights"
        />
      </Helmet>
      <div className="fluid-container">
        <Header />
        <div
          className="jumbotron jumbotron-fluid"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            height: "400px",
          }}
        >
          <div className="containerac">
            <h1 className="display-4 text-center">Contact</h1>
          </div>
        </div>
        <div className="container">
          <h2>Contact Information</h2>
          <div className="contactContainer p-3">
            <div className="row justify-content-center">
              <div className="col-md-3 shadow-lg m-2">
                <h2>
                  <strong>Why Us</strong>
                </h2>
                <ul>
                  <li>Friendly staff</li>
                  <li>Quality service</li>
                  <li>Competitive pricing</li>
                  <li>Licensed &amp; certified</li>
                  <li>Creative &amp; professional</li>
                  <li>Relaxed &amp; comfortable atmosphere</li>
                </ul>
              </div>
              <div className="col-md-3 shadow-lg m-2">
                <h2>
                  <strong>Contact Us</strong>
                </h2>
                <p>
                  <strong>Address:</strong> 7885 Lichen Dr, Citrus Heights, CA,
                  95621
                </p>
                <p>
                  <strong>Call Us:</strong>{" "}
                  <a href="tel:9167221888">(916) 722-1888</a>
                </p>
                <p>
                  <strong>Hours:</strong> Mon - Fri 9 AM - 7 PM Sat - Sun 10 AM
                  - 6 PM
                </p>
              </div>
              <div className="col-md-3 shadow-lg m-2">
                <h2>
                  <strong>Service Area</strong>
                </h2>
                <ul>
                  <li>Your Local Area</li>
                  <li>The Experience You Want</li>
                  <li>The Service You Expect</li>
                  <li>The Quality You Deserve!</li>
                  <li>
                    Call <a href="tel:9167221888">(916) 722-1888</a> for Free
                    Estimate
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Contact;
