import React from "react";

function Footer() {
  var today = new Date();

  return (
    <footer className="py-3 text-center">
      <p className="py-2">
        Copyright &copy; {today.getFullYear()} ERA SALON SAC | All Rights
        Reserved.
      </p>
    </footer>
  );
}

export default Footer;
