import React from "react";
import { Helmet } from "react-helmet";
import Header from "../common/Header";
import Footer from "../common/Footer";

function Gallery() {
  let Background = "/images/gallery.jpg";
  let mappedImages;
  const imageArray = [
    "/images/gallery/galleryimage (8).jpg",
    "/images/gallery/galleryimage (9).jpg",
    "/images/gallery/galleryimage (10).jpg",
    "/images/gallery/galleryimage (11).jpg",
    "/images/gallery/galleryimage (13).jpg",
    "/images/gallery/galleryimage (14).jpg",
    "/images/gallery/galleryimage (15).jpg",
    "/images/gallery/galleryimage (16).jpg",
    "/images/gallery/galleryimage (17).jpg",
    "/images/gallery/galleryimage (18).jpg",
    "/images/gallery/galleryimage (19).jpg",
    "/images/gallery/galleryimage (20).jpg",
    "/images/gallery/galleryimage (21).jpg",
    "/images/gallery/galleryimage (22).jpg",
    "/images/gallery/galleryimage (23).jpg",
    "/images/gallery/galleryimage (24).jpg",
    "/images/gallery/galleryimage (25).jpg",
    "/images/gallery/galleryimage (26).jpg",
    "/images/gallery/galleryimage (27).jpg",
    "/images/gallery/galleryimage (28).jpg",
    "/images/gallery/galleryimage (29).jpg",
    "/images/gallery/galleryimage (30).jpg",
    "/images/gallery/galleryimage (31).jpg",
    "/images/gallery/galleryimage (32).jpg",
    "/images/gallery/galleryimage (33).jpg",
    "/images/gallery/galleryimage (34).jpg",
    "/images/gallery/galleryimage (35).jpg",
    "/images/gallery/galleryimage (36).jpg",
    "/images/gallery/galleryimage (37).jpg",
    "/images/gallery/galleryimage (38).jpg",
    "/images/gallery/galleryimage (39).jpg",
    "/images/gallery/galleryimage (40).jpg",
    "/images/gallery/galleryimage (41).jpg",
    "/images/gallery/galleryimage (43).jpg",
    "/images/gallery/galleryimage (45).jpg",
    "/images/gallery/galleryimage (46).jpg",
    "/images/gallery/galleryimage (47).jpg",
    "/images/gallery/galleryimage (48).jpg",
    "/images/gallery/galleryimage (49).jpg",
    "/images/gallery/galleryimage (50).jpg",
    "/images/gallery/galleryimage (51).jpg",
    "/images/gallery/galleryimage (52).jpg",
    "/images/gallery/galleryimage (53).jpg",
    "/images/gallery/galleryimage (54).jpg",
    "/images/gallery/galleryimage (55).jpg",
    "/images/gallery/galleryimage (56).jpg",
    "/images/gallery/galleryimage (57).jpg",
    "/images/gallery/galleryimage (58).jpg",
    "/images/gallery/galleryimage (59).jpg",
    "/images/gallery/galleryimage (60).jpg",
  ];

  return (
    <div className="galleryContainer">
      <Helmet>
        <title>Gallery - Era Salon</title>
        <meta
          name="description"
          content="Browse our gallery to see the stunning transformations and beautiful results our clients have achieved at Era Salon."
        />
        <meta
          name="keywords"
          content="Gallery, Transformations, Results, Salon, Era Salon, Citrus Heights"
        />
      </Helmet>
      <div className="fluid-container">
        <Header />
        <div
          className="jumbotron jumbotron-fluid"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            height: "400px",
          }}
        >
          <div className="containerac">
            <h1 className="display-4 text-center">Gallery</h1>
          </div>
        </div>
        <div className="container">
          <div className="galleryContainer p-3">
            <h2>
              Welcome to ERA's Salon! Stop in to see our newly remodeled Salon!
            </h2>
            <div className="row justify-content-center">
              {
                (mappedImages = imageArray.map((rows, index) => (
                  <div
                    className="col-sm-12 text-center col-md-4 shadow-sm p-3 border h-100"
                    key={index}
                  >
                    <img src={rows} className="img-thumbnail" alt="Gallery" />
                  </div>
                )))
              }
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Gallery;
