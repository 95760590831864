import React from "react";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Carousel, Button, Modal, Alert } from "react-bootstrap";

import { Link } from "react-router-dom";

import Header from "../common/Header";
import Footer from "../common/Footer";

import Reviews from "./Reviews";

const Home = () => {
  // const [show, setShow] = useState(true);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <div className="homeContainer">
      <Helmet>
        <title>
          Welcome to Era Salon in Citrus Heights - The Best Salon in Citrus
          Heights Sacramento!
        </title>
        <meta
          name="description"
          content="Experience top-notch hair styling, coloring, texturing, facial care, waxing, and nail care at Era Salon. Book an appointment today!"
        />
        <meta
          name="keywords"
          content="Salon, Hair Styling, Hair Coloring, Hair Texturing, Facial Care, Waxing, Nail Care, Beauty, Citrus Heights"
        />
      </Helmet>
      <Header />
      <div className="carouselContainer">
        <div className="fluid-container">
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={process.env.PUBLIC_URL + "/images/homeslider1.jpg"}
                alt="First slide"
              />
              <Carousel.Caption>
                <h3>ERA SALON STAFF</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={process.env.PUBLIC_URL + "/images/homeslider2.jpg"}
                alt="Second slide"
              />

              <Carousel.Caption>
                <h3>ERA SALON FRONT</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={process.env.PUBLIC_URL + "/images/homeslider3.jpg"}
                alt="Third slide"
              />

              <Carousel.Caption>
                <h3>ERA SALON SERVICES</h3>

                <p>
                  Friendly Staff, Quality Service, Competitive Pricing, Licensed
                  &amp; Certified
                </p>

                {/* <Link to="/hair-coloring">
                  <Button variant="primary m-1">Hair Coloring</Button>
                </Link>
                <Link to="hair-styling">
                  <Button variant="primary m-1">Hair Styling</Button>
                </Link>
                <Link to="hair-texturing">
                  <Button variant="primary m-1">Hair Texturing</Button>
                </Link> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={process.env.PUBLIC_URL + "/images/homeslider4.jpg"}
                alt="Fourth slide"
              />
              <Carousel.Caption>
                <h3>Hair Coloring</h3>
                <p>Your Beauty is our Business</p>
                <Link to="/hair-coloring">
                  <Button variant="primary">Hair Coloring</Button>
                </Link>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={process.env.PUBLIC_URL + "/images/homeslider5.jpg"}
                alt="Fourth slide"
              />
              <Carousel.Caption>
                <h3>Hair Styling</h3>
                <Link to="hair-styling">
                  <Button variant="primary">Click Here</Button>
                </Link>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={process.env.PUBLIC_URL + "/images/homeslider6.jpg"}
                alt="Fifth slide"
              />
              <Carousel.Caption>
                <h3>Hair Texturing</h3>
                <Link to="hair-texturing">
                  <Button variant="primary">Click Here</Button>
                </Link>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={process.env.PUBLIC_URL + "/images/homeslider7.jpg"}
                alt="Sixth slide"
              />
              <Carousel.Caption>
                <h3>MEN PROFESSIONAL HAIRCUT</h3>
                <p>Cut Style &amp; Color</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <div className="container">
        <div className="homeServices my-4">
          <h1 className="py-3 text-center">Your Beauty is our Business</h1>

          <h2>
            <strong>Services</strong>
          </h2>

          <div className="row justify-content-center">
            <div className="col-lg-3 shadow-sm p-4 m-2">
              <h3>Hair Coloring/Highlights</h3>
              <p>
                Considering a completely new style is one thing but how else can
                you add zing to your locks?
              </p>
              <Link to="/hair-coloring" className="btn btn-primary">
                Read More
              </Link>
            </div>
            <div className="col-lg-3 shadow-sm p-4 m-2">
              <h3>Hair Straightening</h3>
              <p>
                "ERA SALON SAC" is always up to date on the latest and greatest.
                Your satisfaction is important to us.
              </p>
              <Link to="/hair-styling" className="btn btn-primary">
                Read More
              </Link>
            </div>
            <div className="col-lg-3 shadow-sm p-4 m-2">
              <h3>Nail Care</h3>
              <p>
                We aim to deliver premier service in an intimate, relaxed
                setting. Our friendly staff are here to assist you.
              </p>
              <Link to="/nail-care" className="btn btn-primary">
                Read More
              </Link>
            </div>
            <div className="col-lg-3 shadow-sm p-4 m-2">
              <h3>Microblading</h3>
              <p>
                We offer microblading services for customers that want the
                perfect brows. Have a specific request? Ask us anytime.
              </p>
            </div>
          </div>
        </div>

        <div className="specials">
          <h2>
            <strong>Browse Our Specials</strong>
          </h2>
          <div className="row">
            <Alert variant="info">
              <Alert.Heading>
                <i className="fas fa-info-circle fa-2x"></i>
              </Alert.Heading>
              <p>
                We currently only specialize in offering our brazilian waxing
                services to{" "}
                <strong style={{ textDecoration: "underline" }}>
                  females only
                </strong>{" "}
                and{" "}
                <strong style={{ textDecoration: "underline" }}>DO NOT</strong>{" "}
                offer any waxing services to males. We apologize for this
                inconvenience.
              </p>
            </Alert>
            <div className="col-lg-4">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h5 className="card-title text-center">
                    <strong>Brazilian Wax (females only)</strong>
                  </h5>
                  <p className="card-text">
                    <img
                      src="/images/ERA-Brazilian.jpg"
                      alt="Era Salon Brazilian Wax"
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h5 className="card-title text-center">
                    <strong>FREE Haircut with Color Highlights or Perm</strong>
                  </h5>
                  <p className="card-text">
                    <img
                      src="/images/ERA-Hair-Color.jpg"
                      alt="Era Salon Haircut with Color"
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h5 className="card-title text-center">
                    <strong>Full Nail Set</strong>
                  </h5>
                  <p className="card-text">
                    <img
                      src="/images/ERA-Full-Nail.jpg"
                      alt="Era Salon Full Nail Set"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Reviews */}
        <h2>
          <strong>Reviews</strong>
        </h2>
        <div className="row">
          <OwlCarousel
            className="owl-theme"
            items={1}
            loop={true}
            autoplay={true}
            autoplayTimeout={10000}
          >
            <div className="item">
              <div className="reviewer">
                <p>
                  "I LOVE MY HAIRCUT! And I love this salon! Jenny is the best
                  hairstylist I have ever had. My mom always complains about my
                  haircut but this time SHE LOVES IT! Today was my first time at
                  Era Salon, but I am for sure going to this salon when every
                  time I need to get my hair cut or even get my nails done! VERY
                  RECOMMENDED"
                </p>
                <div className="rating text-center">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
                <div className="quoteName text-right">
                  <strong>- R.F.</strong>
                </div>
              </div>
            </div>
            {/* <div className="item">
                <div className="reviewer">
                  <p>
                    "Jenny gives great haircuts for men too. I can trust her to
                    give a quality haircut at a good price which can be hard to
                    find for a twenty something guy. No need to look elsewhere
                    for me."
                  </p>
                  <div className="rating text-center">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </div>
                  <div className="quoteName text-right">
                    <strong>- S.Guy</strong>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="reviewer">
                  <p>
                    "Tony supper amazing on nails!.frendly plus funny guy too !"
                  </p>
                  <div className="rating text-center">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </div>
                  <div className="quoteName text-right">
                    <strong>- Eng</strong>
                  </div>
                </div>
              </div> */}
          </OwlCarousel>
        </div>
        {/* Reviews */}
        <Reviews />

        {/* Videos */}
        <div className="videos">
          <h2>
            <strong>Videos</strong>
          </h2>
          <div className="row">
            <div className="col-lg-6 shadow-sm">
              <video
                src="https://erasalonbucket.s3.us-west-1.amazonaws.com/Era_Salon.mp4"
                width="100%"
                height="315"
                allowFullScreen
                controls
                title="Era Salon Video Second"
              ></video>
            </div>
            <div className="col-lg-6 shadow-sm">
              <video
                src="https://erasalonbucket.s3.us-west-1.amazonaws.com/Era_Salonvod2.mp4"
                width="100%"
                height="315"
                allowFullScreen
                controls
                title="Era Salon Video Second"
              ></video>
            </div>
            <div className="col-lg-6 shadow-sm">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/owMKb4xWjuI"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
