import React from "react";
import ReactDOM from "react-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';

import routes from "./routes";


ReactDOM.render(
  <div>{routes}</div>,
  document.getElementById('root')
);

