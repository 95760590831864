import React from "react";
import { Helmet } from "react-helmet";
import Header from "../common/Header";
import Footer from "../common/Footer";

function HairStyling() {
  let Background = "/images/hair-styling.jpg";
  return (
    <div className="hairstylingContainer">
      <Helmet>
        <title>Hair Styling Services - Era Salon</title>
        <meta
          name="description"
          content="Discover our range of hair styling services at Era Salon. From cuts to updos, our stylists are here to help you achieve your perfect look."
        />
        <meta
          name="keywords"
          content="Hair Styling, Haircuts, Updos, Salon, Era Salon, Citrus Heights"
        />
      </Helmet>
      <div className="fluid-container">
        <Header />
        <div
          className="jumbotron jumbotron-fluid"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            height: "400px",
          }}
        >
          <div className="container">
            <h1 className="display-4 text-center">Hair Styling</h1>
          </div>
        </div>
        <div className="container">
          <div className="hair">
            <h2>Hair Styling</h2>
            <p>
              <strong>
                "ERA SALON SAC" is always up to date on the latest and greatest.
                When you need something to dazzle them with, our stylists will
                do the trick.
              </strong>
            </p>
            <p>
              We know that you want to look and feel fabulous for your wedding
              or formal event and your hair is your crowning glory. When you
              want to make a lasting impression, let one of our professional
              stylists take care of your needs – everything from bridal
              preparation to formal events.
            </p>
            <p>
              Your satisfaction is important to us. One of the many benefits of
              having a personal hairstylist is that we consult via phone for
              your actual appointment. This way, we know how to help you, and
              your appointment ends when your hair is done.
            </p>
            <p>
              If you prefer a more relaxing experience without the assembly-line
              feeling and impersonal service of the salon, then we will pair you
              up with the perfect hair stylist.
            </p>
            <p>
              <strong>Our hair styling services include:</strong>
            </p>
            <ul>
              <li>Hair cutting and styling</li>
              <li>Hair extensions</li>
              <li>Coloring</li>
              <li>Highlighting</li>
              <li>Perming</li>
              <li>Permanent hair straightening</li>
              <li>Keratin hair straightening</li>
              <li>Brazilian hair straightening</li>
              <li>Japanese hair straightening (lasts up to 6 months)</li>
              <li>Male cuts and styling</li>
              <li>Shampoo</li>
            </ul>
            <p>
              When you are up to your limit of the number of things you have to
              do in a day, come and let us pamper you and your hair. A
              professional styling isn't necessarily a luxury.
            </p>
            <p>
              <strong>
                Whatever your hair needs, we do it. Our full service includes:
                basic haircuts along with hair coloring, perms, waxing services,
                and retail products. We are the experts. We have the training
                and the expertise to create the look and feel you need for any
                occasion.
              </strong>
            </p>
            <p>
              It is our mission to provide the best personal service, catering
              to your desires.
            </p>
            <p>
              Call us at <a href="tel:9167221888">(916) 722-1888</a> to book
              your next appointment. We recommend scheduling your hair
              appointment in advance to assure your desired time.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default HairStyling;
