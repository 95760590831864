import React from "react";

const Reviews = () => {
  return (
    <div className="container formContainer">
      <div className="row text-center">
        <React.Fragment>
          <div className="col-md-3 img-thumbnail m-4">
            <h2 className="text-center py-2">Write a Google Review</h2>
            <a
              href="https://www.google.com/search?sxsrf=ALeKk00mS7SVFP8jWvuxNcrEYyMjfa7DmQ%3A1597278395648&ei=u4g0X9KFJ4H49AP4jongCA&q=era+salon+sac&oq=era+salon+sac&gs_lcp=CgZwc3ktYWIQAzIICAAQsAMQzQIyCAgAELADEM0CMggIABCwAxDNAlAAWABg9MwBaABwAHgAgAFniAFnkgEDMC4xmAEAqgEHZ3dzLXdpesABAQ&sclient=psy-ab&ved=0ahUKEwiSz4Tb9ZbrAhUBPH0KHXhHAowQ4dUDCAw&uact=5"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-google fa-4x pb-2"></i>
            </a>
          </div>
          <div className="col-md-3 img-thumbnail m-4">
            <h2 className="text-center py-2">Write a Yelp Review</h2>
            <a
              href="https://www.yelp.com/biz/era-salon-citrus-heights-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-yelp fa-4x pb-2"></i>
            </a>
          </div>
          <div className="col-md-3 img-thumbnail m-4">
            <h2 className="text-center py-2">Like on Facebook</h2>
            <a
              href="https://www.facebook.com/profile.php?id=100070316466492"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f fa-4x pb-2"></i>
            </a>
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

export default Reviews;
